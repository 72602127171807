import ReactModal from "react-modal";
import styles from "./SaveModal.module.css";
import { TextField } from "../TextField/TextField";
import { Button } from "../Button/Button";
import { FormEvent, MouseEventHandler, useState } from "react";
import isEmail from "validator/lib/isEmail";
import classNames from "classnames";
import { Loader } from "../Loader/Loader";
import { Alert } from "../Alert/Alert";
import posthog from "posthog-js";
import { replacer } from "../../lib/Schema";
import { schemaStore } from "../../lib/schemaStore";
import { useSchemaStore } from "../../lib/useSchemaStore";
import { Card } from "../Card/Card";
import { OldDateField } from "../DateField/OldDateField";
import { faEnvelope, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { apiReq } from "../../lib/apiReq";
import { useReq } from "@larner.dev/use-req";
import { PublicCredentialRecord, UserWithMedia } from "@greenflagdate/shared";

ReactModal.defaultStyles.overlay = {
  ...ReactModal.defaultStyles.overlay,
  backgroundColor: "rgba(0,0,0,0.5)",
  zIndex: 20,
  display: "flex",
  justifyContent: "center",
};

ReactModal.defaultStyles.content = {
  ...ReactModal.defaultStyles.content,
  backgroundColor: "transparent",
  border: "none",
  maxWidth: "30rem",
  position: "absolute",
  inset: "unset",
  insetBlockStart: "6rem",
  insetBlockEnd: "2rem",
  padding: 0,
};

interface SaveModalProps {
  isOpen: boolean;
  onClose: (success: boolean) => void;
  title?: string;
  buttonLabel?: string;
}

interface Errors {
  firstName?: string;
  birthDate?: string;
  email?: string;
  postalCode?: string;
  code?: string;
  default?: string;
}

type SaveType = (e?: FormEvent<HTMLFormElement>) => Promise<void>;
type VerifyType = (e?: FormEvent<HTMLFormElement>) => Promise<void>;

export function SaveModal({
  isOpen,
  onClose,
  title,
  buttonLabel,
}: SaveModalProps) {
  const store = useSchemaStore();
  const [firstName, setFirstName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [email, setEmail] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [code, setCode] = useState("");
  const [errors, setErrors] = useState<Errors>({});
  const [registerRequest, registerRequestState] =
    useReq<PublicCredentialRecord>(apiReq);
  const [verifyRequest, verifyRequestState] = useReq<{
    token: string;
    user: UserWithMedia;
  }>(apiReq);
  const [userRequest, userRequestState] = useReq<UserWithMedia>(apiReq);
  const save: SaveType = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const errors: Errors = {};
    if (!firstName) {
      errors.firstName = "What is your name?";
    }
    if (!birthDate) {
      errors.birthDate = "When were you born?";
    }
    if (!email || !isEmail(email)) {
      errors.email = "Please double check the email";
    }
    if (!postalCode) {
      errors.postalCode = "Where do you live?";
    }
    setErrors(errors);
    if (!Object.keys(errors).length) {
      registerRequestState.clearError();
      const response = await registerRequest.post("/user", {
        email,
      });
      if (!response.success) {
        setErrors({
          default: "Something unexpected went wrong. Please try again.",
        });
      } else {
        const { result } = response;
        schemaStore.setCredentialId(result.id);
      }
    }
  };

  const verify: VerifyType = async (e) => {
    e?.preventDefault();
    posthog.capture("verifyEmailClick");
    const errors: Errors = {};
    if (!code) {
      errors.code = "Please enter the code that was emailed to you.";
    }
    setErrors(errors);
    if (!Object.keys(errors).length) {
      verifyRequestState.clearError();
      const response = await verifyRequest.post("/user/verify", {
        token: `${code}.${store.credentialId}`,
        type: "ReadableToken",
      });
      if (!response.success) {
        posthog.capture("verifyEmailError");
        setErrors({
          default: "Something unexpected went wrong. Please try again.",
        });
      } else {
        const { result } = response;
        schemaStore.setToken(result.token);
        const attributes = {
          first_name: firstName,
          birth_date: birthDate,
          email,
          postal_code: postalCode,
          profile: JSON.stringify(store.modules, replacer),
        };
        const userResponse = await userRequest.put(
          "/user/attributes",
          attributes
        );
        if (userResponse.success) {
          schemaStore.setUser(userResponse.result);
          onClose(true);
        }
      }
    }
  };

  const resend: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    const response = await registerRequest.post("/user", {
      email: email,
    });
    if (!response.success) {
      setErrors({
        default: "Something unexpected went wrong. Please try again.",
      });
    } else {
      schemaStore.setCredentialId(response.result.id);
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      contentElement={() => (
        <Card className={styles.card} onClick={(e) => e.preventDefault()}>
          <div className={styles.container}>
            <div className={styles.header}>
              <Card.Header className={styles.header}>
                <div style={{ paddingRight: "1rem", flex: 1 }}>
                  {title ||
                    "We need a few pieces of information to save your green flags."}
                </div>
                <button
                  className={styles.closeButton}
                  onClick={() => onClose(false)}
                >
                  <FontAwesomeIcon icon={faX} />
                </button>
              </Card.Header>
            </div>
            <div className={styles.content}>
              {store.credentialId && email ? (
                <form className={styles.form} onSubmit={verify}>
                  <div className={classNames(styles.fields, styles.verify)}>
                    <FontAwesomeIcon
                      color="var(--color-blue-100)"
                      icon={faEnvelope}
                      style={{ fontSize: "5rem" }}
                    />

                    <div>
                      We sent an email to <strong>{email}</strong>. Please enter
                      the code from that email to finish saving your progress.
                    </div>
                  </div>
                  {!!registerRequestState.error && (
                    <Alert
                      className={styles.defaultError}
                      title={registerRequestState.error.message}
                      intent="danger"
                    />
                  )}
                  {!!verifyRequestState.error && (
                    <Alert
                      className={styles.defaultError}
                      title={verifyRequestState.error.message}
                      intent="danger"
                    />
                  )}
                  {!!userRequestState.error && (
                    <Alert
                      className={styles.defaultError}
                      title={userRequestState.error.message}
                      intent="danger"
                    />
                  )}
                  <TextField
                    fullWidth
                    placeholder="Code"
                    conatinerClassName={styles.codeContainer}
                    className={styles.codeField}
                    onChange={(e) => setCode(e.target.value)}
                    size="large"
                    error={errors.code}
                  />
                  <button
                    type="button"
                    className={styles.resend}
                    onClick={resend}
                  >
                    {registerRequestState.loading ? (
                      <Loader color="blue" size={20} />
                    ) : (
                      "Re-send code"
                    )}
                  </button>
                  <button
                    type="button"
                    className={styles.resend}
                    onClick={() => schemaStore.setCredentialId()}
                  >
                    Typed the wrong email?
                  </button>
                  <button
                    type="submit"
                    onClick={() => verify()}
                    style={{ visibility: "hidden" }}
                  >
                    Hidden
                  </button>
                </form>
              ) : (
                <form className={styles.form}>
                  <div className={styles.fields}>
                    <label>
                      <div className={styles.label}>FIRST NAME</div>
                      <TextField
                        fullWidth
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        error={errors.firstName}
                      />
                    </label>
                    <label>
                      <div className={styles.label}>BIRTH DATE</div>
                      <OldDateField
                        fullWidth
                        value={birthDate}
                        onChange={setBirthDate}
                        error={errors.birthDate}
                      />
                    </label>
                    <label>
                      <div className={styles.label}>EMAIL ADDRESS</div>
                      <TextField
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={errors.email}
                      />
                    </label>
                    <label>
                      <div className={styles.label}>ZIP CODE</div>
                      <TextField
                        fullWidth
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        error={errors.postalCode}
                      />
                    </label>
                  </div>
                </form>
              )}
            </div>
            <div className={styles.footer}>
              {!store.credentialId ? (
                <Button
                  type="button"
                  label={buttonLabel || "Save Progress"}
                  size="large"
                  loading={registerRequestState.loading}
                  fullWidth
                  onClick={() => save()}
                />
              ) : (
                <Button
                  type="button"
                  label={buttonLabel || "Verify Email"}
                  size="large"
                  loading={verifyRequestState.loading}
                  fullWidth
                  onClick={() => verify()}
                />
              )}
            </div>
          </div>
        </Card>
      )}
    ></ReactModal>
  );
}
