import classNames from "classnames";
import posthog from "posthog-js";
import { PropsWithChildren, useEffect, useState } from "react";
import { MarketingHeader } from "../MarketingHeader/MarketingHeader";
import styles from "./Page.module.css";
import { Outlet, useLocation } from "react-router-dom";
import { Meta } from "../Meta/Meta";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Pane, PaneProps } from "evergreen-ui";
import { backgroundColor } from "../../lib/useBackgroundColor";
import { useStoreData } from "../../lib/store/store";
dayjs.extend(localizedFormat);

export interface PageProps extends PropsWithChildren<PaneProps<"main">> {
  hideMarketingHeader?: boolean;
  className?: string;
}

export function Page({
  hideMarketingHeader,
  className,
  children,
  ...props
}: PageProps) {
  const location = useLocation();
  const [bgColor, setBgColor] = useState(backgroundColor.val);
  const { safeArea } = useStoreData();

  hideMarketingHeader =
    hideMarketingHeader ||
    location.pathname.toLowerCase() === "/app" ||
    location.pathname.toLowerCase().startsWith("/app/");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    posthog.init(import.meta.env.VITE_POSTHOG_KEY as string, {
      api_host: "https://app.posthog.com",
    });
    posthog.capture("$pageview");
  }, []);
  useEffect(() => {
    setBgColor(backgroundColor.val);
    function bgColorChange(event: Event) {
      setBgColor((event as CustomEvent<{ color: string }>).detail.color);
    }
    backgroundColor.addEventListener("update", bgColorChange);
    return () => {
      backgroundColor.removeEventListener("update", bgColorChange);
    };
  }, []);

  return (
    <Pane
      className={classNames(
        styles.container,
        { [styles.marketingHeader]: !hideMarketingHeader },
        className
      )}
      paddingTop={safeArea.top}
      paddingRight={safeArea.right}
      paddingBottom={safeArea.bottom}
      paddingLeft={safeArea.left}
      is="main"
      backgroundColor={bgColor ? bgColor : undefined}
      {...props}
    >
      <Meta>
        <meta name="description" content="Figure out your dating style" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content="" />
        <meta property="og:type" content="" />
        <meta property="og:url" content="" />
        <meta property="og:image" content="" />

        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Meta>
      {!hideMarketingHeader && <MarketingHeader />}
      {children ? children : <Outlet />}
    </Pane>
  );
}
