import { produce } from "immer";
import { Action, ActionType, handlers } from "./storeActions";
import { StoreSchema } from "./StoreSchema";
import { toaster } from "evergreen-ui";
import { useEffect, useState } from "react";
import { apiReq } from "../apiReq";
import { UserWithMedia } from "@greenflagdate/shared";
import { ReqMethod } from "@larner.dev/req";

declare global {
  interface Window {
    gfStore: Store;
  }
}

export const LOGIN_TOKEN_KEY = "GF_TOKEN";
export const SPARK_REGISTRATION_KEY = "GF_SPARK_REGISTRATION";

const generateEmptyStore = (): StoreSchema => {
  const token = localStorage.getItem(LOGIN_TOKEN_KEY);
  if (token) {
    apiReq.headers.authorization = `Bearer ${token}`;
    (async () => {
      try {
        const user = await apiReq.standardRequest<UserWithMedia>(
          "/user/me",
          ReqMethod.GET
        );
        if (user) {
          store.dispatch({
            type: ActionType.UpdateUser,
            params: {
              user,
            },
          });
        } else {
          store.dispatch({
            type: ActionType.LogOut,
            params: {},
          });
        }
      } catch (error) {
        console.log(error);
        store.dispatch({
          type: ActionType.LogOut,
          params: {},
        });
      }
      store.dispatch({
        type: ActionType.SetLoading,
        params: {
          loading: false,
        },
      });
    })();
  }

  const sparkRegistrationString = localStorage.getItem(SPARK_REGISTRATION_KEY);
  let sparkRegistration = {};
  if (sparkRegistrationString) {
    try {
      sparkRegistration = JSON.parse(sparkRegistrationString);
    } catch (error) {
      console.log("spark registration parse", error);
    }
  }

  return {
    loading: !!token,
    safeArea: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    transitionType: "push",
    sparkRegistration,
    auth: {
      token: token || undefined,
    },
  };
};

class Store extends EventTarget {
  private privateData: StoreSchema = generateEmptyStore();
  dispatch(action: Action) {
    let result: unknown = null;
    const newData = produce(this.privateData, (draft) => {
      try {
        // @ts-expect-error we know that action.type should accept action.params but typescript does not
        result = handlers[action.type](action.params, draft);
      } catch (e) {
        console.trace(e);
        if (e instanceof Error) {
          toaster.danger(e.message);
        } else if (typeof e === "string") {
          toaster.danger(e);
        } else {
          toaster.danger(String(e));
        }
      }
    });
    if (this.privateData !== newData) {
      this.privateData = newData;
      this.dispatchEvent(
        new CustomEvent("update", { detail: { store: newData } })
      );
    }
    return result;
  }

  get data(): StoreSchema {
    return Object.freeze(this.privateData);
  }
}

export const store = new Store();

window.gfStore = store;

export const useStoreData = () => {
  const [storeData, setStoreData] = useState(store.data);
  useEffect(() => {
    const updateHandler: EventListener = (updateEvent) => {
      setStoreData(
        (updateEvent as CustomEvent<{ store: StoreSchema }>).detail.store
      );
    };
    store.addEventListener("update", updateHandler);
    setStoreData(store.data);
    return () => {
      store.removeEventListener("update", updateHandler);
    };
  }, []);
  return storeData;
};
