import { useEffect } from "react";
import styles from "./Events.module.css";
import { Loader } from "../../../components/Loader/Loader";
import { Link } from "react-router-dom";
import { Link as StyledLink } from "../../../components/Link/Link";
import { Meta } from "../../../components/Meta/Meta";
import dayjs from "dayjs";
import { H2 } from "../../../components/Heading/Heading";
import { Pane } from "evergreen-ui";
import Markdown from "react-markdown";
import { useReq } from "@larner.dev/use-req";
import { cmsRequest } from "../../../lib/cmsHelpers";
import { CMSEventData } from "@greenflagdate/shared";

export const Events = () => {
  const [request, requestState] = useReq<CMSEventData>(cmsRequest);
  useEffect(() => {
    request.get(
      `/api/events?filters[registrationEndsAt][$gte]=${new Date().toISOString()}&populate[0]=socialShareImage`
    );
  }, [request]);

  if (!requestState.result || requestState.loading) {
    return (
      <div className={styles.loadingContainer}>
        <Loader />
      </div>
    );
  }

  if (!requestState.loading) {
    if (requestState.error) {
      return <div>{requestState.error.toString()}</div>;
    }
  }

  const data = requestState.result.data.sort(
    (a, b) =>
      new Date(b.attributes.registrationEndsAt).getTime() -
      new Date(a.attributes.registrationEndsAt).getTime()
  );

  return (
    <>
      <Meta>
        <title>Green Flag Events</title>
        <meta property="og:title" content="Green Flag Events" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.greenflagdate.com/events"
        />
      </Meta>
      <Pane maxWidth="40rem" marginX="auto" marginTop="3rem" paddingX="2rem">
        {data.length ? (
          <>
            <Pane marginBottom="2rem">
              All Events are currently in Atlanta. If you are interested in
              hosting an event in your city please{" "}
              <StyledLink to="/contact">let us know!</StyledLink>
            </Pane>
            {data.map((event) => (
              <Link
                className={styles.event}
                to={`/event/${event.attributes.slug}`}
                key={event.id}
              >
                <div className={styles.imageHolder}>
                  {event.attributes.socialShareImage?.data && (
                    <img
                      src={
                        event.attributes.socialShareImage.data.attributes.url
                      }
                      alt={`${event.attributes.title} image`}
                      className={styles.socialShareImage}
                      width={
                        event.attributes.socialShareImage.data.attributes.width
                      }
                      height={
                        event.attributes.socialShareImage.data.attributes.height
                      }
                    />
                  )}
                </div>
                <div className={styles.eventContent}>
                  <Pane marginBottom="0.5rem">
                    Registration closes{" "}
                    {dayjs(event.attributes.registrationEndsAt).format("ll")}
                  </Pane>
                  <H2 marginBottom="0.5rem">{event.attributes.title}</H2>
                  <Pane marginBottom="0.5rem">
                    <Markdown allowedElements={[]} unwrapDisallowed>
                      {event.attributes.subtitle}
                    </Markdown>
                  </Pane>
                  <div className={styles.readMore}>Click for details...</div>
                </div>
              </Link>
            ))}
          </>
        ) : (
          <Pane marginBottom="2rem">
            We don't have any events scheduled at this time. If you are
            interested in hosting an event in your city please{" "}
            <StyledLink to="/contact">let us know!</StyledLink>
          </Pane>
        )}
      </Pane>
    </>
  );
};
