import {
  Button,
  IconButton,
  Pane,
  Paragraph,
  PlusIcon,
  TextInput,
} from "evergreen-ui";
import { PageHeader } from "../../../components/PageHeader/PageHeader";
import { H2 } from "../../../components/Heading/Heading";
import { sparkActivities } from "../../../lib/sparkActivites";
import { useNavigate } from "react-router-dom";
import { store, useStoreData } from "../../../lib/store/store";
import React, { useCallback, useMemo, useState } from "react";
import { ActionType } from "../../../lib/store/storeActions";
import { VerticalGroup } from "../../../components/VerticalGroup/VerticalGroup";
import { useGFTheme } from "../../../lib/themes";
import { ToggleButton } from "../../../components/ToggleButton/ToggleButton";

export function Spark() {
  const navigate = useNavigate();
  const storeData = useStoreData();
  const [customSpark, setCustomSpark] = useState<string>("");
  const { colors } = useGFTheme();
  const activities = useMemo(
    () => storeData.sparkRegistration.activities || [],
    [storeData.sparkRegistration.activities]
  );
  const toggle = useCallback(
    (val: string) => () => {
      store.dispatch({
        type: ActionType.UpdateSparkRegistration,
        params: {
          activities: activities.includes(val)
            ? activities.filter((n) => n !== val)
            : [...activities, val],
        },
      });
    },
    [activities]
  );
  return (
    <Pane>
      <PageHeader>
        <PageHeader.Title maxWidth="28rem">
          Tired of swiping and never meeting in person?
        </PageHeader.Title>
      </PageHeader>
      <Pane maxWidth="35rem" padding="2rem" marginX="auto">
        <H2>Introducing Sparks!</H2>
        <Paragraph size={500} marginTop="1rem">
          Meet 7 - 15 other single people doing activities you love!{" "}
        </Paragraph>
        <H2 marginTop="2.5rem">1. Choose your Sparks</H2>
        <VerticalGroup
          marginTop="1rem"
          gap="0.25rem"
          selectors={{
            "& > button": {
              borderRadius: "1rem",
            },
          }}
        >
          {Array.from(new Set([...sparkActivities, ...activities])).map((a) => (
            <ToggleButton
              key={a}
              margin="0.25rem"
              height="2rem"
              isActive={activities.includes(a)}
              onClick={toggle(a)}
            >
              {a}
            </ToggleButton>
          ))}
        </VerticalGroup>
        <Pane
          is="form"
          display="flex"
          marginTop="1rem"
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            if (customSpark) {
              toggle(customSpark)();
              setCustomSpark("");
            }
          }}
        >
          <TextInput
            width="100%"
            placeholder="Add your own!"
            borderTopRightRadius="0"
            borderBottomRightRadius="0"
            value={customSpark}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCustomSpark(e.target.value)
            }
          />
          <IconButton
            icon={<PlusIcon color="white" />}
            appearance="primary"
            borderRadius="5px"
            borderTopLeftRadius="0"
            borderBottomLeftRadius="0"
            height="46px"
          />
        </Pane>
        <Button
          appearance="primary"
          width="100%"
          marginTop="2.5rem"
          onClick={() =>
            navigate(
              `/app/spark/${
                activities.length > 0 ? "availability" : "activities"
              }`
            )
          }
        >
          Next: Choose your preferences
        </Button>
      </Pane>
      <PageHeader>
        <Pane maxWidth="28rem">
          <H2 marginBottom="1rem">What are Sparks?</H2>
          <Paragraph size={500} color={colors.greenDark} marginBottom="2.5rem">
            Sparks are small (8 - 16 person), host-free singles events{" "}
            <strong>
              designed to foster natural, in-person connections through curated
              activities.
            </strong>
          </Paragraph>
          <H2 marginBottom="1rem">How it Works</H2>
          <Paragraph size={500} color={colors.greenDark} marginBottom="2.5rem">
            <ol>
              <li>
                <strong>Opt Into a Spark:</strong> Each week by Wednesday at
                11:59pm, opt into joining a Spark event for the following week.
              </li>
              <li>
                <strong>Tell Us Your Preferences:</strong> Select activities
                you're interested in and your preferred neighborhoods around the
                city.
              </li>
              <li>
                <strong>Get Your Invite:</strong> If there are enough people
                that have your same preferences, you'll be put into a group and
                get all of the deets for your Spark!
              </li>
              <li>
                <strong>Let Sparks Fly:</strong> Head to your meeting spot at
                7pm, and let the activities begin! After each activity we'll
                send an email giving you the chance to mutually connect with
                anyone there.
              </li>
            </ol>
          </Paragraph>
          <H2 marginBottom="1rem">Why come to a Spark?</H2>
          <Paragraph size={500} color={colors.greenDark} marginBottom="2.5rem">
            <ol>
              <li>
                <strong>Curated Experiences:</strong> Meet others with similar
                interests through fun activities like cooking classes, trivia
                nights, or pickleball.
              </li>
              <li>
                <strong>Small Groups:</strong> With 10-16 people, you'll have
                the chance to truly connect with everyone.
              </li>
              <li>
                <strong>Like-Minded Singles:</strong> Skip the apps — everyone
                at Sparks is here to meet in person.
              </li>
              <li>
                <strong>Fun & Low-Pressure:</strong> Relax, have fun, and let
                the activities spark connections naturally.
              </li>
            </ol>
          </Paragraph>
        </Pane>
      </PageHeader>
    </Pane>
  );
}
