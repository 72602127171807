import classNames from "classnames";
import { MouseEventHandler, useState } from "react";
import { Hamburger } from "./Hamburger";
import styles from "./MarketingHeader.module.css";
import logo from "./images/logo.png";
import { Button } from "../Button/Button";
import posthog from "posthog-js";
import { useSchemaStore } from "../../lib/useSchemaStore";
import { Link } from "react-router-dom";
import { schemaStore } from "../../lib/schemaStore";

export function MarketingHeader() {
  const [navOpen, setNavOpen] = useState(false);
  const store = useSchemaStore();

  const onNavClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    setNavOpen(false);
    const url = new URL(e.currentTarget.href);
    if (url.pathname === "/") {
      const el = document.getElementById(url.hash.substring(1));
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
        e.preventDefault();
      }
    }
  };
  return (
    <div className={styles.content}>
      <div className={styles.logoSpacer}>
        <Link to="/" className={styles.logoLink}>
          <img src={logo} alt="Green Flag logo" width={150} />
        </Link>
      </div>

      <div className={styles.storeLinks}>
        {!store.token || !store.user?.slug ? (
          <Button
            label="Get Started"
            href="/discover"
            size={"medium"}
            onClick={() =>
              posthog.capture("marketingHeaderCtaClick", {
                text: "Get Started",
              })
            }
          />
        ) : (
          <Button
            label="View My Profile"
            href={`/${store.user.slug}`}
            size={"medium"}
            onClick={() =>
              posthog.capture("marketingHeaderCtaClick", {
                text: "View My Profile",
              })
            }
          />
        )}

        {/* <Link to="/sign-up?utm_source=app_store" className={styles.appStore}>
          <Image src={appStore} alt="Download on the App Store" />
        </Link>
        <Link
          href="/sign-up?utm_source=play_store"
          className={styles.playStore}
        >
          <Image src={playStore} alt="Get it on Google Play" height={40} />
        </Link> */}
      </div>
      <Hamburger
        open={navOpen}
        onClick={setNavOpen}
        className={styles.hamburger}
      />
      <div
        className={classNames(styles.navLinks, { [styles.navOpen]: navOpen })}
      >
        <Link to="/events" onClick={onNavClick}>
          EVENTS
        </Link>
        {/* <Link to="/#course" onClick={onNavClick}>
          INTRO COURSE
        </Link> */}
        <Link to="/team" onClick={onNavClick}>
          TEAM
        </Link>
        <Link to="/blog" onClick={onNavClick}>
          BLOG
        </Link>
        <Link to="/contact" onClick={onNavClick}>
          CONTACT
        </Link>
        {!store.token ? (
          <Link
            to="/sign-in"
            onClick={onNavClick}
            className={styles.lastTextNavLink}
          >
            SIGN IN
          </Link>
        ) : (
          <Link
            to="#"
            onClick={(e) => {
              schemaStore.logOut();
              onNavClick(e);
            }}
            className={styles.lastTextNavLink}
          >
            SIGN OUT
          </Link>
        )}

        {!store.token || !store.user?.slug ? (
          <Button
            label="Get Started"
            href="/discover"
            size={"medium"}
            className={styles.getStarted}
            onClick={() =>
              posthog.capture("marketingHeaderCtaClick", {
                text: "Get Started",
              })
            }
          />
        ) : (
          <Button
            label="View My Profile"
            href={`/${store.user.slug}`}
            size={"medium"}
            className={styles.getStarted}
            onClick={() =>
              posthog.capture("marketingHeaderCtaClick", {
                text: "View My Profile",
              })
            }
          />
        )}
      </div>
    </div>
  );
}
