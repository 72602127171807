import { VerticalGroup } from "../VerticalGroup/VerticalGroup";
import { ToggleButton } from "../ToggleButton/ToggleButton";
import { GenderPreference } from "@greenflagdate/shared";

interface GenderPreferenceProps {
  value?: string;
  onChange: (value?: GenderPreference) => void;
}

export const GenderPreferenceSelector = ({
  value,
  onChange,
}: GenderPreferenceProps) => {
  return (
    <VerticalGroup width="100%">
      <ToggleButton
        width="100%"
        onClick={() => onChange("man")}
        isActive={value?.toLowerCase() === "man"}
      >
        Men
      </ToggleButton>
      <ToggleButton
        width="100%"
        onClick={() => onChange("woman")}
        isActive={value?.toLowerCase() === "woman"}
      >
        Women
      </ToggleButton>
      <ToggleButton
        width="100%"
        onClick={() => onChange("everyone")}
        isActive={value?.toLowerCase() === "everyone"}
      >
        Everyone
      </ToggleButton>
    </VerticalGroup>
  );
};
