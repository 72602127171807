import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Page } from "../components/Page/Page";
import { Contact } from "../pages/marketing/Contact/Contact";
import { SignIn } from "../pages/marketing/SignIn/SignIn";
import { Profile } from "../pages/marketing/Profile/Profile";
import { Blog } from "../pages/marketing/Blog/Blog";
import { BlogContents } from "../pages/marketing/Blog/BlogContents";
import { BlogPost } from "../pages/marketing/Blog/BlogPost";
import { Discover } from "../pages/marketing/Discover/Discover";
import { Newsletter } from "../pages/marketing/Newsletter/Newsletter";
import { NewsletterSuccess } from "../pages/marketing/Newsletter/NewsletterSuccess";
import { DiscoverModule } from "../pages/marketing/Discover/DiscoverModule";
import { DiscoverSection } from "../pages/marketing/Discover/DiscoverSection";
import { DiscoverQuestion } from "../pages/marketing/Discover/DiscoverQuestion";
import { DiscoverOutcome } from "../pages/marketing/Discover/DiscoverOutcome";
import { DiscoverMyGreenFlags } from "../pages/marketing/Discover/DiscoverMyGreenFlags";
import { GFThemeProvider } from "../components/GFThemeProvider/GFThemeProvider";
import { Event } from "../pages/marketing/Event/Event";
import { EventDetails } from "../pages/marketing/Event/EventDetails";
import { EventSuccess } from "../pages/marketing/Event/EventSuccess";
import { EventCheckout } from "../pages/marketing/Event/EventCheckout";
import { Events } from "../pages/marketing/Event/Events";
import { handleIncomingNativeEvent } from "../lib/mobileHelpers";
import { sendToMobile, MobileActionType } from "../lib/mobile/mobileActions";
import { IncomingNativeEvent } from "../lib/NativeEvent";
import { Policy } from "../pages/marketing/Policy/Policy";
import { Legal } from "../pages/marketing/Legal/Legal";
import { Spark } from "../pages/marketing/Spark/Spark";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Page />,
    children: [
      {
        path: "",
        async lazy() {
          const { Index } = await import("../pages/marketing/Index/Index");
          return { Component: Index };
        },
      },
      {
        path: "app/*",
        async lazy() {
          const { App } = await import("../pages/app/App/App");
          return { Component: App };
        },
      },
      {
        path: "team",
        async lazy() {
          const { Team } = await import("../pages/marketing/Team/Team");
          return { Component: Team };
        },
        children: [],
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "spark",
        element: <Spark />,
      },
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "blog",
        element: <Blog />,
        children: [
          {
            index: true,
            element: <BlogContents />,
          },
          {
            path: ":postSlug",
            element: <BlogPost />,
          },
        ],
      },
      {
        path: "event/:eventSlug",
        element: <Event />,
        children: [
          {
            index: true,
            element: <EventDetails />,
          },
          {
            path: "success",
            element: <EventSuccess />,
          },
          {
            path: "checkout",
            element: <EventCheckout />,
          },
        ],
      },
      {
        path: "events",
        element: <Events />,
      },
      {
        path: "newsletter",
        children: [
          {
            index: true,
            element: <Newsletter />,
          },
          {
            path: "success",
            element: <NewsletterSuccess />,
          },
        ],
      },
      {
        path: "discover",
        element: <Discover />,
        children: [
          {
            path: ":moduleSlug/:sectionSlug/outcome",
            element: <DiscoverOutcome />,
          },
          {
            path: ":moduleSlug/:sectionSlug/:questionId",
            element: <DiscoverQuestion />,
          },
          {
            path: ":moduleSlug/:sectionSlug",
            element: <DiscoverSection />,
          },
          {
            path: "my-green-flags",
            element: <DiscoverMyGreenFlags />,
          },
          {
            path: ":moduleSlug",
            element: <DiscoverModule />,
          },
        ],
      },
      {
        path: "legal",
        element: <Legal />,
      },
      {
        path: "policy/:slug",
        element: <Policy />,
      },
      {
        path: "*",
        element: <Profile />,
      },
    ],
  },
  {},
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <GFThemeProvider>
      <RouterProvider router={router} />
    </GFThemeProvider>
  </React.StrictMode>
);

window.addEventListener("nativeEvent", (event: Event) => {
  const { detail } = event as CustomEvent<IncomingNativeEvent>;
  handleIncomingNativeEvent(detail);
});

sendToMobile({
  type: MobileActionType.GetSafeArea,
  params: undefined,
});

// Adjust the viewport height on load and resize
function updateVhUnit() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

window.addEventListener("resize", updateVhUnit);
window.addEventListener("load", updateVhUnit);

// Call it once to set the initial value
updateVhUnit();
